export default {
    "Categories": "Kategorien",
    "Search results for &ldquo;{query}&rdquo;": "Suchergebnisse f\u00fcr &ldquo;{query}&rdquo;",
    "What do our customers say": "Was unsere Kunden sagen",
    "{shop} scores a {average} based on {total} reviews": "{shop} ist eine {im} Durchschnitt basierend auf {average} gesamt",
    "By": "von",
    "Subscribe": "Anmeldung",
    "Created by": "Realisierung Webshop",
    "{count} ratings": "{0} 0 Bewertungen|{1} {count} Bewertung|[2,*] {count} Bewertungen",
    "Alternate Products": "Alternative Produkte",
    "Product Description": "Produktbeschreibung",
    "Specifications": "Spezifikationen",
    "Related Products": "Dazu passende Produkte",
    "Size Chart": "Gr\u00f6\u00dfentabelle",
    "Size Advice": "Gr\u00f6\u00dfenempfehlung",
    "Reviews": "Bewertungen",
    "All about this product": "\u00dcber dieses Produkt",
    "All about the {name}": "\u00dcber {name}",
    "Read more": "Mehr lesen",
    "Rating of <span>&rdquo;{name}&ldquo;</span>": "Bewertung von <span>&rdquo;{name}&ldquo;</span>",
    "<span>{rating}</span> based on <span>{count}</span> reviews": "<span>{rating}</span> basierend auf <span>{count}</span> Bewertungen",
    "Written by {name} on {date}": "Von {name} am {date}",
    "Thank you for your order!": "Vielen Dank f\u00fcr deine Bestellung!",
    "Your order has been placed successfully.": "Wir haben deine Bestellung erhalten.",
    "Your ordernumber is: #{order_id}.": "Deine Bestellnummer ist: #{order_id}.",
    "You will receive an e-mail confirming your order with all the details within 15 minutes. Also check your spam folder if you have not received a confirmation email after 15 minutes. If you have any questions about the status of your order, it is best to contact customer service.": "Du solltest innerhalb von 15 Minuten per Email eine Bestellbest\u00e4tigung mit allen Details erhalten. Falls du keine Best\u00e4tigungs-Email erhalten hast, \u00fcberpr\u00fcfe bitte ggfs. deinen Spam-Ordner. Bei allen Fragen zum Status deiner Bestellung, kontaktiere bitte den Kundenservice.",
    "Billing address": "Rechnungsadresse",
    "Shipping address": "Lieferadresse",
    "Pick up your order at:": "Hier kannst du deine Bestellung abholen:",
    "Recent Blog Posts": "Aktuelle Blog-Beitr\u00e4ge",
    "Previous": "Vorheriger",
    "Next": "N\u00e4chster",
    "Tags": "Tags",
    "Products by this post": "Produkte in diesem Beitrag",
    "Login": "Anmelden",
    "Email address": "Email-Adresse",
    "Password": "Passwort",
    "Confirm password": "Passwort best\u00e4tigen",
    "Forgot password?": "Passwort vergessen?",
    "Benefits of an account": "Vorteile eines Kontos",
    "Create account": "Neu registrieren",
    "You are now signed out": "Du hast dich nun abgemeldet",
    "You are now logged out and will return to our home page within 5 seconds.": "Du hast dich nun abgemeldet und wirst innerhalb von 5 Sekunden zur Startseite geleitet.",
    "First name": "Vorname",
    "Last name": "Nachname",
    "Date of birth": "Geburtsdatum",
    "DD": "TT",
    "MM": "MM",
    "YYYY": "JJJJ",
    "Male": "m\u00e4nnlich",
    "Female": "weiblich",
    "Subscribe to our newsletter": "Melde dich f\u00fcr unseren Newsletter an",
    "Welcome {name}": "Willkommen {name}",
    "This personal page offers you an overview of the most recent activities. You can, among other things, view orders and update account information. Click on a link below to get started!": "diese Seite bietet dir einen \u00dcberblick \u00fcber deine letzten Aktivit\u00e4ten. Hier kannst du auch Bestellungen einsehen und Kontoinformationen aktualisieren!",
    "Recent orders": "Deine letzten Bestellungen",
    "You have not placed any orders yet": "Es liegen noch keine Bestellungen vor",
    "Order #": "Bestellung #",
    "Date": "Datum",
    "Ship to": "Versendet an",
    "Total": "Gesamt",
    "View order": "Bestellung anschauen",
    "Personal settings": "Pers\u00f6nliche Einstellungen",
    "Contact details": "Kontakt-Informationen",
    "Edit": "\u00e4ndern",
    "Change password": "Passwort \u00e4ndern",
    "Newsletter": "Newsletter",
    "You are subscribed to our newsletter": "Du hast dich f\u00fcr unseren Newsletter angemeldet",
    "subscribe": "Melde dich an",
    "Addresses": "Adressen",
    "Default shipping address": "Standard Lieferadresse",
    "No default shipping address available": "Keine Standard-Lieferadresse vorhanden",
    "Default billing address": "Standard Rechnungsadresse",
    "No default billing address available": "Keine Strandard-Rechnungsadresse vorhanden",
    "Edit personal settings": "Pers\u00f6nliche Einstellungen \u00e4ndern",
    "Current password": "Aktuelles Passwort",
    "New password": "Neues Passwort",
    "Confirm new password": "Neues Passwort best\u00e4tigen",
    "Save": "Speichern",
    "Add new address": "Neue Adresse hinzuf\u00fcgen",
    "Company": "Firma",
    "Phone number": "Telefonnummer",
    "Address": "Adresse",
    "Postal code + house number": "PLZ + Hausnr.",
    "Postal code": "PLZ",
    "House no.": "Hausnr.",
    "Street": "Stra\u00dfe",
    "City": "Stadt",
    "Country": "Land",
    "Select a country": "W\u00e4hle ein Land",
    "Save address": "Adresse speichern",
    "Edit address": "Adresse \u00e4ndern",
    "Extra Addresses": "Zus\u00e4tzliche Adressen",
    "No addresses available": "Keine Adresse vorhanden",
    "Remove": "L\u00f6schen",
    "Account overview": "Konto-\u00dcbersicht",
    "My orders": "Meine Bestellungen",
    "My reviews": "Meine Bewertungen",
    "Newsletters": "Newsletter-Abos",
    "Wishlist": "Meine Wunschliste",
    "Logout": "Abmelden",
    "Do you receive our newsletter?": "Erh\u00e4ltst Du unseren Newsletter?",
    "Order {increment}": "Bestellung {increment}",
    "Payment method": "Zahlart",
    "Shipping method": "Versand-Option",
    "Ordered items": "Bestellte Artikel",
    "Item": "Artikel",
    "SKU": "Art.Nr.",
    "Price": "Preis",
    "Quantity": "Anzahl",
    "Subtotal": "Zwischensumme",
    "Shipping": "Versand",
    "Free": "Gratis",
    "Grand total": "Gesamtsumme",
    "Request password": "Neues Passwort erstellen",
    "You have not reviewed any products yet": "Du hast noch keine Produktbewertungen abgegeben",
    "Previously viewed by you": "Zuvor von dir angeschaut",
    "Apply": "Sichern",
    "Cancel": "Abbrechen",
    "Search for...": "Suche nach...",
    "More results for {inputQuery}": "Weitere Ergebnisse f\u00fcr {inputQuery}",
    "No results found for {inputQuery}": "Keine Ergebnisse gefunden f\u00fcr {inputQuery}",
    "Select an option": "W\u00e4hle eine Option",
    "More": "Mehr",
    "Be inspired": "Lass dich inspirieren",
    "Active filters": "Aktive Filter",
    "More options": "mehr",
    "Less options": "weniger",
    "to": "bis",
    "Enter a correct value": "Gebe einen korrekten Wert ein",
    "Sizes": "Gr\u00f6\u00dfe",
    "Filter": "Filter",
    "Show results": "Ergebnisse anzeigen",
    "Show {totalItems} results": "Keine Ergebnisse | Zeige 1 Ergebnis | Zeige {totalItems} Ergebnisse",
    "No products were found in this category.": "Keine Artikel wurden in dieser Kategorie gefunden.",
    "{count} results": "Keine Ergebnisse | 1 Ergebnis | {count} Ergebnisse",
    "Sort by": "Sortieren nach",
    "Latest releases": "Neuste",
    "Name (A-Z)": "Name (A-Z)",
    "Name (Z-A)": "Name (Z-A)",
    "Price (Ascending)": "Preis (tief-hoch)",
    "Price (Descending)": "Preis (hoch-tief)",
    "Choose your {option}": "W\u00e4hle deine {option}",
    "Notify me when my size is back in stock": "Benachrichtigt mich, wenn meine Gr\u00f6\u00dfe wieder auf Lager ist",
    "Thank you for your request, you will be notified by mail when the size is back in stock!": "Vielen Dank f\u00fcr deine Anfrage, du wirst per Mail von uns benachrichtigt, wenn deine Gr\u00f6\u00dfe wieder auf Lager ist!",
    "Out of stock notification": "Ausverkauft-Meldung",
    "Choose your size": "W\u00e4hle deine Gr\u00f6\u00dfe",
    "Send": "Senden",
    "You don't seem to be logged in, please login to enable stock notifications.": "Du scheinst nicht angemeldet zu sein. Bitte melde dich an, um Lager-Benachrichtigungen zu aktivieren.",
    "If you don't already have an account, you can register one {here}": "Falls du kein Kundenkonto hast kannst du dich {here} registrieren",
    "here": "hier",
    "By {name} on {date}": "Von {name} am {date}",
    "Added to cart": "Zum Warenkorb hinzugef\u00fcgt",
    "To shopping cart": "Zum Warenkorb",
    "Nice to add": "Passend hinzuf\u00fcgen",
    "You may also like this": "Das k\u00f6nnte dir auch gefallen",
    "Select one of the required options.": "W\u00e4hle eine der notwendigen Optionen.",
    "Choose your personalisation options": "W\u00e4hle deine Bedruckungs-Optionen",
    "Personalize product": "Bedrucke Artikel",
    "Personalize product with name and/or number": "Bedrucke den Artikel mit Name und/oder Nummer!",
    "Choose your stringing personalisation options": "W\u00e4hle deine Bespannung",
    "Personalize product with stringing": "Bespann-Service",
    "Personalize product with stringing and/or weight": "Konfiguriere deinen Schl\u00e4ger mit der Saite deiner Wahl sowie dem gew\u00fcnschten Zuggewicht",
    "Snaar": "Saite",
    "Gewicht": "Besaitungs-H\u00e4rte",
    "Choose {option}": "W\u00e4hle deine {option}",
    "Add to cart": "In den Warenkorb",
    "Featured": "Empfehlung",
    "Pay safe": "Sicher bezahlen",
    "Write a review": "Schreibe eine Bewertung",
    "Your rating": "Deine Bewertung",
    "name": "Name",
    "Enter your name": "Dein Name",
    "description": "Beschreibung",
    "Short description of your review": "Kurze Titel Deiner Bewertung",
    "review": "Deine Bewertung",
    "Submit review": "Bewertung absenden",
    "Unable to save review": "Bewertung kann nicht gesendet werden",
    "Thanks for placing a review!": "Vielen Dank f\u00fcr deine Bewertung!",
    "Shopping cart": "Warenkorb",
    "To checkout": "Zur Kasse",
    "One or more products are out of stock.": "Ein oder mehrere Artikel sind leider nicht vorr\u00e4tig",
    "Continue shopping": "Weiter einkaufen",
    "You have no product(s) in your shopping cart.": "Im Warenkorb befinden sich keine Artikel",
    "Click {url} to continue shopping": "Klick {url} um weiter einzukaufen",
    "Loading shopping cart items...": "Dein Einkauf wird geladen...",
    "Product out of stock": "Artikel ist ausverkauft",
    "Ordered before 22:00, <span>delivered for free</span> the next day": "Bis 14:00 bestellt, <span>am gleichen Tag Mo.-Fr. versandkostenfrei versendet</span>",
    "Sold out! Will be expected at <span>{date}</span>.": "Derzeit ausverkauft! Wird wieder auf Lager erwartet am <span>{date}</span>.",
    "Will be expected at <span>{date}</span>.": "Wird wieder auf Lager erwartet am <span>{date}</span>.",
    "Sold out!": "Der Artikel ist leider ausverkauft",
    "Expected delivery: {date}": "Voraussichtliche Lieferzeit: {date} Werktage",
    "Delivery costs": "Versandkosten",
    "Discount": "Ersparnis gegen\u00fcber UVP:",
    "Payment": "Bezahlung",
    "Complete!": "Fertig!",
    "Log in": "Anmelden",
    "Name": "Name",
    "Choose your country": "W\u00e4hle dein Land",
    "House number": "Hausnr.",
    "Addition": "Zusatz",
    "Add.": "Zusatz",
    "Telephone": "Telefonnr.",
    "Click here to automatically retrieve your address": "Klick hier, um deine Adresse automatische einzuf\u00fcgen",
    "We have found the following address": "Wir haben folgende Adresse gefunden",
    "Invalid address. Are you sure it is correct?": "Ung\u00fcltige Adresse. Bist du sicher, dass sie korrekt ist?",
    "Click here to enter the address manually.": "Klick hier um deine Adresse manuell einzugeben.",
    "Select your address": "W\u00e4hle deine Adresse",
    "Login with your account": "Anmelden mit deinem Konto",
    "You have an account. Would you like to {action}?": "Du hast ein Konto. M\u00f6chtest du dich {action}?",
    "login": "anmelden",
    "I want to add a comment": "Eine Bemerkung abgeben",
    "Write your comment": "Formuliere deine Bemerkung",
    "Without extra fees": "Ohne zus\u00e4tzl. Kosten",
    "Credit card": "Kreditkarte",
    "Choose your credit card": "W\u00e4hle deine Kreditkarte",
    "The most used payment method in The Netherlands": "Die meist genutzte Zahlart in den Niederlanden",
    "Bank": "Bank",
    "Choose your bank": "W\u00e4hle deine Bank",
    "Do you already have an account with us? Log in {action} and we will get your data.": "Hast du bereits ein Konto bei uns? Melde dich an {action} so dass wir deine Daten \u00fcbernehmen k\u00f6nnen.",
    "Billing address is different from the address above": "Rechnungsadresse unterscheidet sich von der o.g. Adresse",
    "Sign me up for the newsletter": "F\u00fcr den Newsletter bitte anmelden",
    "Create an account": "Pers\u00f6nliches Konto erstellen",
    "Payment methods": "Zahlarten",
    "Something went wrong, the payment could not be completed. Please try again or choose a different payment method.": "Etwas ist schiefgelaufen, die Zahlung konnte nicht durchgef\u00fchrt werden. Bitte versuche es nochmal oder w\u00e4hle eine andere Zahlart.",
    "Choose a payment method to complete your order": "W\u00e4hle eine Zahlart um deine Bestellung abzuschlie\u00dfen",
    "Shipping methods": "Versand-Optionen",
    "Choose a delivery method to proceed to the next step": "W\u00e4hle eine Versand-Option, um mit dem n\u00e4chsten Schritt fortzufahren",
    "Overview of your order": "\u00dcbersicht zu deiner Bestellung",
    "You can pick up your order at": "Lieferadresse Deiner Bestellung",
    "Your order will be delivered to": "Deine Bestellung wird versendet an",
    "Change delivery address": "Lieferadresse \u00e4ndern",
    "Some items in your shopping cart are out of stock. {action}": "Einige Artikel in deinem Warenkorb sind z.Zt. nicht auf Lager. {action}",
    "View products.": "Artikel anschauen.",
    "Previous step": "Schritt zur\u00fcck",
    "To shipping": "Zum Versand",
    "Continue": "Weiter",
    "Complete order": "Kaufen",
    "I have a discount code": "Ich habe einen Rabatt-Code",
    "Your applied coupon code is {couponCodeInput}.": "Dein Rabatt-Code lautet {couponCodeInput}.",
    "Select a {carrier} pick-up point": "W\u00e4hle eine {carrier} DHL Packstation",
    "Please wait, we are loading the stores ...": "Einen Moment Geduld, wir laden alle DHL Packstationen ...",
    "personal ID": "DHL Postnummer (6-10 Zahlen)",
    "{distance} kilometer": "{distance} Kilometer",
    "{distance} meter": "{distance} Meter",
    "Opening hours": "\u00d6ffnungszeiten",
    "Enter your postal code": "Gebe deine Postleitzahl ein",
    "My wishlist": "Meine Wunschliste",
    "There are no products in your wishlist": "Deine Wunschliste ist noch leer",
    "Request return": "R\u00fccksendung beantragen",
    "Sign up": "Anmeldung",
    "Conditions": "Bedingungen",
    "Instructions": "Anweisungen",
    "Thanks": "Vielen Dank",
    "Order number": "Bestellnr.",
    "Submit complaint": "Reklamation anmelden",
    "Order": "Bestellung",
    "Order Date:": "Bestelldatum:",
    "Items ordered": "Bestellte Artikel",
    "Product": "Artikel",
    "Qty": "Anzahl",
    "Return": "R\u00fccksendung",
    "Do you have a comment for this return?": "Hast Du eine Bemerkung zu der R\u00fccksendung?",
    "Submit return": "R\u00fccksendung anmelden",
    "Accept terms and conditions on return submit note": "AGBs zur R\u00fccksendung akzeptieren.",
    "Print return ticket": "R\u00fccksendeschein ausdrucken",
    "Finalize return": "R\u00fccksendung abschlie\u00dfen",
    "Upload image": "Bild hochladen",
    "No image chosen": "Kein Bild ausgew\u00e4hlt",
    "Complaint is a required field.": "Reklamation ist ein Pflichtfeld.",
    "Something went wrong, please try again later": "Etwas ist schiefgelaufen, bitte versuche es ggfs. sp\u00e4ter noch einmal",
    "ordered": "bestellt",
    "Can't be returned": "Kann nicht zur\u00fcckgesendet werden",
    "Already returned": "bereits zur\u00fcckgesendet",
    "Message": "Nachricht",
    "Submit": "Abschicken",
    "Contact us": "Kontaktiere unseren Kundenservice",
    "Invalid re-captcha.": "Ung\u00fcltiges re-captcha.",
    "Your message has been sent.": "Deine Nachricht wurde versendet.",
    "validation.required": "Feld '{attribute}' ist ein Pflichtfeld",
    "validation.email": "Ung\u00fcltige Email-Adresse in Feld '{attribute}'",
    "All {label}": "Alle {label}",
    "Choose your": "W\u00e4hle deine",
    "Grand Total": "Gesamtsumme",
    "Forgot password": "Passwort vergessen?",
    "Successfully created an account, you can log in with your new credentials": "Du hast erfolgreich ein Kundenkonto erstellt und kannst dich jetzt mit deinen Zugangsdaten einloggen",
    "If an account is found with {email}, you will receive an email with a link to reset your password.": "Wenn ein Kundenkonto unter {email} gefunden wurde, haben wir dir dorthin eine E-Mail mit einem Link gesendet, um dein Passwort zur\u00fcckzusetzen. Bitte \u00fcberpr\u00fcfe ggfs. auch Deinen Spam-Ordner.",
    "Thank you for your order, {name}": "Vielen Dank f\u00fcr deine Bestellung, {name}",
    "You will soon receive an email with the details of your order. For questions about your order, visit our <a href=\"/contact\">customer service</a> and contact us!": "Du erh\u00e4ltst in K\u00fcrze eine E-Mail mit den Details deiner Bestellung. Bei Fragen zu deiner Bestellung, kontaktiere bitte unseren <a href=\"/contact\">Kundenservice</a>!",
    "Your order number is: <strong>#{orderid}</strong>": "Deine Bestellnummer ist: <strong>#{orderid}</strong>",
    "Best regards, {store}": "Dein {store} TEAM",
    "We expect to deliver on": "Vorrausichtlicher Versandtag",
    "We will send a confirmation by e-mail to": "Bestellbest\u00e4tigung versenden wir an",
    "Do you have any questions?": "Hast du noch Fragen?",
    "Visit our <a href=\"/contact\"> customer service </a>": "Kontaktiere unseren <a href=\"/contact\">Kundenservice</a>",
    "Prefix": "Anrede",
    "Sir.": "Herr.",
    "Mrs.": "Frau.",
    "Birthday": "Geburtstag",
    "I accept the Riverty {url} *": "Ich akzeptiere die Riverty {url} *",
    "terms of payment": "Zahlungsbedingungen",
    "tomorrow": "morgen",
    "Monday": "Montag",
    "Tuesday": "Dienstag",
    "Wednesday": "Mittwoch",
    "Thursday": "Donnerstag",
    "Friday": "Freitag",
    "Saturday": "Samstag",
    "Sunday": "Sonntag",
    "Closed": "Geschlossen",
    "recommended": "Favorit",
    "This is a secure connection": "Dies ist eine sichere Verbindung",
    "Create an account for your next visit!": "Erstelle ein Konto f\u00fcr deinen n\u00e4chsten Besuch!",
    "The benefits of an account:": "Die Vorteile eines Kontos:",
    "Order comments": "Track & Trace zu deiner Bestellung",
    "contact_form.subject": "Kontaktformular {store}Name",
    "Search results": "Suchergebnisse",
    "Recommended": "Empfohlen",
    "new": "Neu",
    "Pickup point": "Packstation",
    "Something went wrong while restoring your password": "Diese Email-Adresse haben wir nicht gefunden. Bitte verwende eine bereits registrierte Email-Adresse oder registriere Dich mit dieser Email einfach neu!",
    "Your password has been updated": "Dein Passwort wurde aktualisiert",
    "Please enter a valid code": "Der Gutscheincode ist nicht g\u00fcltig. \u00dcberpr\u00fcfe den Code und versuche es erneut.",
    "The coupon code could not be cancelled, please try again later.": "Der Gutscheincode konnte nicht storniert werden, bitte versuchen Sie es sp\u00e4ter erneut.",
    "Choose your FREE product": "W\u00e4hle deinen GRATIS Artikel",
    "Please wait, your return request is being processed.": "Bitte warte einen Moment, dein R\u00fccksendeantrag wird verarbeitet. Das kann einige Sekunden dauern.",
    "Apologies, something went wrong processing your return request.": "Entschuldigung, bei der Bearbeitung deines R\u00fccksendeantrag ist etwas schiefgelaufen.",
    "Please contact our customer service team for assistance at info@sportshop.com or on 0031416-652803.": "Kontaktiere bitte den Kundenservice unter kundenservice@passasports.com oder rufe uns unter +49-(0)221-82829909 an.",
    "Status": "Status",
    "Used coupon": "Gebrauchter Gutscheincode",
    "Click here to follow your order": "Hier klicken, um deine Bestellung zu verfolgen",
    "My account": "Mein Kundenkonto",
    "Reset password": "Passwort \u00e4ndern",
    "Cart": "Wagen",
    "Checkout": "Auschecken",
    "Thank you. We have received your complaint!": "Wir danken Ihnen. Wir haben deine Reklamation erhalten!",
    "Download invoice": "Download Rechnung",
    "Choose your color": "W\u00e4hle deine Farbe",
    "Color": "Farbe",
    "Sorry we're down for maintenance": "Entschuldigung, wegen Wartungsarbeiten ist die Seite aktuell nicht erreichbar.",
    "We'll be back shortly": "Wir sind in K\u00fcrze zur\u00fcck!",
    "Your recommended size is currently not available!": "Die von Ihnen empfohlene Gr\u00f6\u00dfe ist derzeit nicht verf\u00fcgbar!",
    "The cache of {cache} has been emptied": "Der Cache von {cache} wurde geleert",
    "The cache could not be cleared": "Der Cache konnte nicht geleert werden",
    "The coupon code isn't valid. Verify the code and try again.": "Dieser Rabattcode ist nicht bekannt. Hast du vielleicht einen Geschenkgutschein/Giftcard? Dann gehe bitte zu den Zahlungsmethoden und w\u00e4hle dort die Option \u201eGiftcard\" aus.",
    "To Kiyoh reviews page": "Geben Sie Ihre Meinung ab",
    "All fields must be filled": "Alle Felder m\u00fcssen ausgef\u00fcllt werden.",
    "You already have an account with us. Please log in or continue without an account.": "Du hast bereits ein Konto bei uns. Bitte melde dich an oder setze den Vorgang ohne ein Kundenkonto fort.",
    "You can create an account after you\u2019ve purchased the order.": "Du kannst nach Abschluss des Bestellvorgangs ein Kundenkonto erstellen.",
    "Did you mean {email}?": "Meintest du {email}?",
    "Delete account": "Konto l\u00f6schen",
    "Yes, I want to permanently delete my account": "Ja, ich m\u00f6chte mein Konto dauerhaft l\u00f6schen",
    "Enter your giftcard number and email address, a new giftcard will be send to this email.": "Gib deine Gutscheinnummer und deine E-Mail-Adresse ein, ein neuer Geschenkgutschein wird an diese E-Mail geschickt.",
    "Redeem giftcard": " Geschenkgutschein einl\u00f6sen",
    "Giftcard": "Geschenkgutschein",
    "Successfully requested new giftcard. Please check your email for the new giftcard code.": "Neuer Geschenkgutschein erfolgreich angefordert. Bitte pr\u00fcfe deine E-Mail auf den neuen Gutscheincode.",
    "Could not collect balance for given giftcard code. Please try again later or contact support.": "Das Guthaben f\u00fcr den angegebenen Gutscheincode konnte nicht erfasst werden. Bitte versuche es sp\u00e4ter noch einmal. Oder kontaktiere den Kundenservice.",
    "Add set to cart": "Set in den Warenkorb",
    "{name} has been added to your cart.": "{name} wurde deinem Warenkorb hinzugef\u00fcgt.",
    "Max {count} characters": "Max {count} Zeichen",
    "Personalisation Title": "Personifiera din produkt",
    "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.": "Die Kombination aus E-Mail-Adresse und Passwort ist bei uns unbekannt.",
    "Repeat email address": "Wiederholen Sie Ihre E-Mail-Adresse",
    "E-mail addresses do not match.": "E-Mail-Adressen stimmen nicht \u00fcberein.",
    "Store": "Laden",
    "Order for your team": "Bestellen Sie f\u00fcr Ihr Team",
    "Add player | Add players": "Spieler hinzuf\u00fcgen | Spieler hinzuf\u00fcgen",
    "Player": "Spieler",
    "Check if all players meet the required options.": "\u00dcberpr\u00fcfen Sie, ob alle Spieler die erforderlichen Optionen erf\u00fcllen.",
    "Not all sizes are selected.": "Nicht alle Gr\u00f6\u00dfen sind ausgew\u00e4hlt.",
    "Swipe for more products": "Wischen f\u00fcr mehr Produkte",
    "Order this set for your entire team!": "Bestellen Sie dieses Set f\u00fcr Ihr gesamtes Team!",
    "{qty} pieces for {price} each": "{qty} St\u00fcck f\u00fcr jeweils {price}",
    "Tierprices": "Staffelpreise",
    " - {stock} in stock": " - {stock} auf Lager",
    "Choose your delivery time:": "W\u00e4hlen Sie Ihren Liefertag:",
    "Loading totals summary...": "Einen Moment Geduld...",
    "Only {qty} available!": "Nur {qty} verf\u00fcgbar!",
    "Something went wrong, contact us for help.": "Etwas ist schiefgelaufen, kontaktiere uns f\u00fcr Hilfe.",
    "Show more": "Mehr anzeigen",
    "Other sports": "Andere Sportarten",
    "Billing- and/or shipping address is not valid": "\u00dcberpr\u00fcfen Sie Ihre Adressdaten",
    "Back": "Zur\u00fcck",
    "How does it work?": "Wie funktioniert das?",
    "Remove product": "Produkt entfernen",
    "Add product": "Produkt hinzuf\u00fcgen",
    "Desired delivery day": "Gew\u00fcnschter Liefertermin",
    "This product is in stock": "Dieses Produkt ist auf Lager",
    "Available sizes": "Verf\u00fcgbare Gr\u00f6\u00dfen",
    "Minimum length of this field must be equal or greater than {length} symbols. Leading and trailing spaces will be ignored.": "Die Mindestl\u00e4nge dieses Feldes muss gleich oder gr\u00f6\u00dfer als {length} Zeichen sein. F\u00fchrende und nachfolgende Leerzeichen werden ignoriert.",
    "Minimum of different classes of characters in password is {count}. Classes of characters: Lower Case, Upper Case, Digits, Special Characters.": "Das Passwort muss mindestens {count} verschiedene Zeichens\u00e4tze enthalten. Zeichens\u00e4tze: Kleinbuchstaben, Gro\u00dfbuchstaben, Ziffern, Sonderzeichen.",
    "The first name is required.": "Der Vorname ist erforderlich.",
    "The first name must be a valid string.": "Der Vorname muss eine g\u00fcltige Zeichenkette sein.",
    "The first name must be at least 2 characters long.": "Der Vorname muss mindestens 2 Zeichen lang sein.",
    "The first name must not exceed 255 characters.": "Der Vorname darf nicht l\u00e4nger als 255 Zeichen sein.",
    "The last name is required.": "Der Nachname ist erforderlich.",
    "The last name must be a valid string.": "Der Nachname muss eine g\u00fcltige Zeichenkette sein.",
    "The last name must be at least 2 characters long.": "Der Nachname muss mindestens 2 Zeichen lang sein.",
    "The last name must not exceed 255 characters.": "Der Nachname darf nicht l\u00e4nger als 255 Zeichen sein.",
    "The email address is required.": "Die E-Mail-Adresse ist erforderlich.",
    "Please provide a valid email address.": "Bitte geben Sie eine g\u00fcltige E-Mail-Adresse ein.",
    "The email address must not exceed 255 characters.": "Die E-Mail-Adresse darf nicht l\u00e4nger als 255 Zeichen sein.",
    "The day of birth is required.": "Der Geburtstagstag ist erforderlich.",
    "The day of birth must be a number.": "Der Geburtstagstag muss eine Zahl sein.",
    "The day of birth must be at least 1.": "Der Geburtstagstag muss mindestens 1 sein.",
    "The month of birth is required.": "Der Geburtsmonat ist erforderlich.",
    "The month of birth must be a number.": "Der Geburtsmonat muss eine Zahl sein.",
    "The month of birth must be at least 1.": "Der Geburtsmonat muss mindestens 1 sein.",
    "The year of birth is required.": "Das Geburtsjahr ist erforderlich.",
    "The year of birth must be a number.": "Das Geburtsjahr muss eine Zahl sein.",
    "The year of birth must be at least 4 digits long.": "Das Geburtsjahr muss mindestens 4 Ziffern lang sein.",
    "The gender must be either Male or Female.": "Das Geschlecht muss entweder m\u00e4nnlich oder weiblich sein.",
    "The password is required.": "Das Passwort ist erforderlich.",
    "The password confirmation does not match.": "Die Passwortbest\u00e4tigung stimmt nicht \u00fcberein.",
    "As early as possible": "So schnell wie m\u00f6glich",
    "We expect to deliver your complete order on {date}": "Wir erwarten, dass wir Ihre gesamte Bestellung am {date} liefern",
    "The payment provider rejected the payment request, please use another payment method.": "Der Zahlungsanbieter hat die Zahlungsanfrage abgelehnt, bitte verwenden Sie eine andere Zahlungsmethode.",
    "The payment is canceled, the order is not paid": "Die Zahlung wurde abgebrochen, die Bestellung ist nicht bezahlt",
    "An error occurred during the payment process, please try again or use another payment method.": "W\u00e4hrend des Zahlungsvorgangs ist ein Fehler aufgetreten, bitte versuchen Sie es erneut oder verwenden Sie eine andere Zahlungsmethode.",
    "Returns": "R\u00fccksendungen",
    "Search your orders": "Suche deine Bestellung",
    "Search": "Suchen",
    "No order found with order id {orderId}": "Keine Bestellung mit der Bestellnummer {orderId} gefunden",
    "Order placed on": "Bestellung aufgegeben am",
    "An error occurred while fetching the order. Please try again later.": "Beim Abrufen der Bestellung ist ein Fehler aufgetreten. Bitte versuchen Sie es sp\u00e4ter erneut.",
    "Choose return reason": "R\u00fccksendegrund ausw\u00e4hlen",
    "Size": "Gr\u00f6\u00dfe",
    "I want to return my products": "Ich m\u00f6chte meine Produkte zur\u00fccksenden",
    "By initiating a return, you agree to our return policy.": "Mit der Beauftragung deiner R\u00fccksendung stimmst du unserer R\u00fcckgaberichtlinie zu.",
    "Download return label": "R\u00fccksendeetikett herunterladen",
    "This product has custom personalisation options.": "Dieses Produkt hat individuelle Personalisierungsoptionen.",
    "An error occurred while retrieving the order. Please check your details or try again later.": "Beim Abrufen der Bestellung ist ein Fehler aufgetreten. Bitte \u00fcberpr\u00fcfen Sie Ihre Angaben oder versuchen Sie es sp\u00e4ter erneut.",
    "Oops, something went wrong": "Hoppla, etwas ist schiefgelaufen",
    "My returns": "Meine R\u00fccksendungen",
    "Why?": "Warum?",
    "We recommend that you order <strong>one size larger</strong> than you normally wear.": "Wir empfehlen Ihnen, <strong>eine Nummer gr\u00f6\u00dfer</strong> zu bestellen, als Sie normalerweise tragen.",
    "Do you want to merge your old and new shopping basket?": "Willst du deinen alten und neuen Warenkorb zusammenf\u00fchren?",
    "You already had items in your shopping basket. Would you like to merge them with the items you have now added?": "Du hattest bereits Artikel in deinem Warenkorb. M\u00f6chtest du sie mit den jetzt hinzugef\u00fcgten Artikeln zusammenf\u00fchren?",
    "No. Only continue with newly added items": "Nein. Nur mit neu hinzugef\u00fcgten Artikeln fortfahren.",
    "Yes. Merge and continue.": "Ja. Zusammenf\u00fchren und fortfahren.",
    "Successfully saved address": "Adresse gespeichert",
    "I would like to:": "Ich m\u00f6chte:",
    "Track my order": "Meine Bestellung verfolgen",
    "Download my invoice": "Meine Rechnung herunterladen",
    "Return my products": "Meine Produkte zur\u00fccksenden",
    "An error occurred while fetching the return label. Please try again later.": "Beim Abrufen des R\u00fccksendeetiketts ist ein Fehler aufgetreten. Bitte versuchen Sie es sp\u00e4ter erneut.",
    "Read our return policy": "Lesen Sie unsere R\u00fcckgaberichtlinie"
}